@import url("https://fonts.googleapis.com/css2?family=Alice&display=swap");
html {
  background-color: #000;
}

body {
  font-family: "Alice";
  overflow-y: hidden;
  overflow-x: hidden;
}

.container {
  color: #f8f7f2;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 60vh;
}

.left-content {
  padding: 2rem;
  border-right: 2px solid #f8f7f2;
}

.right-content {
  padding-left: 2rem;
  padding-right: 2rem;
}

.full-width {
  color: #f8f7f2;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: 20vh; */
  padding: 2rem;
  font-family: "Alice";
  line-height: 0em;
}

a {
  color: inherit;
  text-decoration: inherit;
}

a:hover {
  opacity: 0.7;
}

.google-form {
  width: 100%;
}
.order {
  width: 99.5vw;
  height: 100vh;
  overflow-y: hidden;
  scroll-behavior: inherit;
  scroll-snap-type: none;
  scroll-margin: 10px;
}

.nav-back {
  z-index: 1000;
  color: #f8f7f2;
  font-size: 1.75rem;
  font-family: "Alice";
  float: left;
  position: absolute;
  margin: 2rem;
  font-weight: 800;
}
